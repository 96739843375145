import React, { useRef, useState, useEffect } from 'react';
import './style.css';
import Navbar from './Navbar';
import { Typography } from '@mui/material';
import Footer from './Footer';
const GeneralURLs = [
    "https://cdn.apiframe.pro/videos/35339044217834680654560118666859.mp4",
    "https://cdn.apiframe.pro/videos/65141650208964547900865581021880.mp4",
    "https://cdn.apiframe.pro/videos/24961941088050023654203001806234.mp4",
    "https://cdn.apiframe.pro/videos/03300988715187934185084711833568.mp4",
    "https://cdn.apiframe.pro/videos/87394809885016177531809223155433.mp4",
    "https://cdn.apiframe.pro/videos/94352595047534365939281596182655.mp4",
    "https://cdn.apiframe.pro/videos/34358855446587618395212626522247.mp4",
    "https://cdn.apiframe.pro/videos/43111512496228700257207797905905.mp4",
    "https://cdn.apiframe.pro/videos/78962632036437505477170370468752.mp4",
    "https://cdn.apiframe.pro/videos/60886941833014870326032910149782.mp4",
];
const HistoryURls = [
    "https://cdn.apiframe.pro/videos/46334329760876201958185534684013.mp4",
    "https://cdn.apiframe.pro/videos/14130823733478742796754934187826.mp4",
    "https://cdn.apiframe.pro/videos/77688445241141587167162987733242.mp4",
    "https://cdn.apiframe.pro/videos/22435550419890134151082167504141.mp4",
    "https://cdn.apiframe.pro/videos/01323490373396780172902791072102.mp4",
    "https://cdn.apiframe.pro/videos/28390978887967135023598501534455.mp4",
    "https://cdn.apiframe.pro/videos/53048140283887080587296051283455.mp4",
    "https://cdn.apiframe.pro/videos/09645662021399860492434976775504.mp4",
    "https://cdn.apiframe.pro/videos/14615738369183453953405636985228.mp4",
    "https://cdn.apiframe.pro/videos/57477827155263852700875795641567.mp4",
    "https://cdn.apiframe.pro/videos/51886026300450737789248468919717.mp4",
    "https://cdn.apiframe.pro/videos/26557287120193821107373041718539.mp4",
    "https://cdn.apiframe.pro/videos/76094463648996268147879740037574.mp4",
];
const naturalURLs = [
    "https://cdn.apiframe.pro/videos/35278490608527265659037191115655.mp4",
    "https://cdn.apiframe.pro/videos/72126315186187421324043783196532.mp4",
    "https://cdn.apiframe.pro/videos/94174815157708904662922111067217.mp4",
    "https://cdn.apiframe.pro/videos/46756609407908183059567228285147.mp4",
    "https://cdn.apiframe.pro/videos/07658943376795188681369496502334.mp4",
    "https://cdn.apiframe.pro/videos/90465521796532729705117990292132.mp4",
    "https://cdn.apiframe.pro/videos/95671943368403228381721572787374.mp4",
    "https://cdn.apiframe.pro/videos/38350765500461978117264618103433.mp4",
    "https://cdn.apiframe.pro/videos/99113720847924399386885480333098.mp4",
    "https://cdn.apiframe.pro/videos/49081188354535433661477945193557.mp4",
    "https://cdn.apiframe.pro/videos/92426072773612588842182838436167.mp4",
    "https://cdn.apiframe.pro/videos/22049473393891975411483584261460.mp4",
];
const animalURLs = [
    "https://cdn.apiframe.pro/videos/16055477969778003036641680050423.mp4",
    "https://cdn.apiframe.pro/videos/96572992817579432539366715467237.mp4",
    "https://cdn.apiframe.pro/videos/72126315186187421324043783196532.mp4",
    "https://cdn.apiframe.pro/videos/69362229113643420404416470530032.mp4",
    "https://cdn.apiframe.pro/videos/10132818068519304816529485358622.mp4",
    "https://cdn.apiframe.pro/videos/84812332356042637834846538009538.mp4",
    "https://cdn.apiframe.pro/videos/09145658685536567108040238380107.mp4",
    "https://cdn.apiframe.pro/videos/93250160568285858541211771409533.mp4",
    "https://cdn.apiframe.pro/videos/57775338082932023833689721729425.mp4",
    "https://cdn.apiframe.pro/videos/66653613536765155401442462751682.mp4",
    "https://cdn.apiframe.pro/videos/20446001205871905557242450302586.mp4",
    "https://cdn.apiframe.pro/videos/51394113946636086860837729038417.mp4"
];
const featureURLs = [
    "https://cdn.apiframe.pro/videos/72435857926304357424882489640312.mp4",
    "https://cdn.apiframe.pro/videos/71562602858878235221933914676236.mp4",
    "https://cdn.apiframe.pro/videos/27334254823489927194677706559664.mp4",
    "https://cdn.apiframe.pro/videos/04210110594633746902500232721091.mp4",
    "https://cdn.apiframe.pro/videos/64923900825186360861907735130429.mp4",
    "https://cdn.apiframe.pro/videos/84640547129845587040748483240991.mp4",
    "https://cdn.apiframe.pro/videos/57044162399492425157555782503599.mp4",
    "https://cdn.apiframe.pro/videos/31058427346358770412222152274184.mp4",
    "https://cdn.apiframe.pro/videos/76997426878099120888991470301867.mp4"
];
const VideoGrid = () => {
    const [activeTab, setActiveTab] = useState('History');
    const videoRefs = useRef([]);
    const handleMouseEnter = (index) => {
        videoRefs.current.forEach((video, i) => {
            if (i !== index && video) video.pause();
        });
        const video = videoRefs.current[index];
        if (video) {
            video.play();
        }
    };
    const handleMouseLeave = (index) => {
        const video = videoRefs.current[index];
        if (video) {
            video.pause();
            video.currentTime = 0;
        }
    };
    const getVideos = () => {
        switch (activeTab) {
            case 'General':
                return GeneralURLs;
            case 'Natural':
                return naturalURLs;
            case 'Animal':
                return animalURLs;
            case 'Features':
                return featureURLs;
            default:
                return HistoryURls;
        }
    };
    return (
        <div style={{ backgroundColor: '#1a223f' }}>
            <Navbar />
            <Typography variant="h5" sx={{ textAlign: 'center', color: '#fff', fontSize: { xs: '24px', md: '32px' }, fontWeight: 'bold' }}>
                Text to Video
            </Typography>
            <Typography sx={{ textAlign: 'center', fontSize: '24px', fontFamily: 700, color: '#fff' }} variant="body1">Explore the Types of Stunning AI Videos We Create.</Typography>

            <div className="tabs">
                <button onClick={() => setActiveTab('History')} className={activeTab === 'History' ? 'active' : ''}>
                    Historical
                </button>
                <button onClick={() => setActiveTab('Natural')} className={activeTab === 'Natural' ? 'active' : ''}>
                    Natural
                </button>
                <button onClick={() => setActiveTab('Animal')} className={activeTab === 'Animal' ? 'active' : ''}>
                    Animal
                </button>
                <button onClick={() => setActiveTab('Features')} className={activeTab === 'Features' ? 'active' : ''}>
                    Cars/Bike
                </button>
                <button onClick={() => setActiveTab('General')} className={activeTab === 'General' ? 'active' : ''}>
                    Random
                </button>
            </div>
            {/* Video grid */}
            <div className="video-grid">
                {getVideos().map((url, index) => (
                    <div
                        className="video-item"
                        key={url} // Use the video URL as the key to avoid key conflicts
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                    >
                        <video
                            id={`video-${index}`}
                            ref={(el) => (videoRefs.current[index] = el)}
                            className="video-player"
                            width="100%"
                            muted
                            // autoPlay
                            loop
                        >
                            <source src={url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                ))}
            </div>
            <Footer />

        </div>
    );
};
export default VideoGrid;