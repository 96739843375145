import { useTheme } from "@mui/material";
import { useEffect } from "react";


const useConfig = () => {
    const theme = useTheme();
    let mode  = theme.palette;

    return mode;
}

export default useConfig