import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import ForgotPassword from 'views/pages/authentication/auth-forms/ForgotPassword';
import ResetPassword from 'views/pages/authentication/auth-forms/ResetPassword';
import PricingPage from 'views/pages/langingPage/Pricing-page';
import TextToVideoContainer from 'views/pages/langingPage/TextToVideo';

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const LandingPage = Loadable(lazy(() => import('views/pages/langingPage/index')));
const AudioModal = Loadable(lazy(() => import('views/pages/langingPage/TextToAudio')));
const ImageModal = Loadable(lazy(() => import('views/pages/langingPage/ImageGeneration')));
const AboutUs = Loadable(lazy(() => import('views/pages/langingPage/About')));
const ContactUS = Loadable(lazy(() => import('views/pages/langingPage/Contact')));
const PrivacyPolicy = Loadable(lazy(() => import('views/pages/langingPage/privacy-policy')));
const TermsOfService = Loadable(lazy(() => import('views/pages/langingPage/terms-and-service')));

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin3 />
    },
    {
      path: '/signup',
      element: <AuthRegister3 />
    },
    {
      path: '/landing-page',
      element: <LandingPage />
    },
    {
      path: '/subscription-plan',
      element: <PricingPage />
    },
    {
      path: 'video',
      element: <TextToVideoContainer />
    },
    {
      path: '/audio-modal',
      element: <AudioModal />
    },
    {
      path: '/image-modal',
      element: <ImageModal />
    },
    {
      path: '/about',
      element: <AboutUs />
    },
    {
      path: '/contact',
      element: <ContactUS />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: '/terms-of-service',
      element: <TermsOfService />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />
    },
    {
      path: 'reset-password/:token',
      element: <ResetPassword />
    }
  ]
};

export default AuthenticationRoutes;
