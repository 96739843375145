import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, Container, Box, Tabs, Tab, Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';


const PayPalSubscriptionForm = () => {

    // useEffect(() => {
    //     // Load the PayPal SDK script dynamically for subscription button
    //     const script1 = document.createElement('script');
    //     script1.src = 'https://www.paypal.com/sdk/js?client-id=AbRLlHH8bL6MdlW1bCt9rz96P-nWw6YrSEGyZkoGvsAJnj2OAMUroSTpOR4Uhi0nNMQ4SF_nnljDzDjl&vault=true&intent=subscription';
    //     script1.async = true;
    //     script1.onload = () => {
    //         // Render PayPal subscription buttons
    //         window.paypal.Buttons({
    //             style: {
    //                 shape: 'pill',
    //                 color: 'blue',
    //                 layout: 'vertical',
    //                 label: 'subscribe'
    //             },
    //             createSubscription: function (data, actions) {
    //                 return actions.subscription.create({
    //                     /* Creates the subscription */
    //                     plan_id: 'P-8SD58849JF360221AM3MT7AQ'
    //                 });
    //             },
    //             onApprove: function (data, actions) {
    //                 alert(`Subscription successful! Subscription ID: ${data.subscriptionID}`);
    //             }
    //         }).render('#paypal-button-container-subscription'); // Renders the PayPal subscription button
    //     };
    //     document.body.appendChild(script1);

    //     // Load the PayPal SDK script for hosted buttons
    //     const script2 = document.createElement('script');
    //     script2.src = 'https://www.paypal.com/sdk/js?client-id=BAAKwTUqP_gNTUsh_ATD2tj4zKPzxSZavY2Jt3Lu7MXMv3KD0uxYVEqIKyiDv2_aWpAT2HpYnOIbfX3ZrU&components=hosted-buttons&disable-funding=venmo&currency=USD';
    //     script2.async = true;
    //     script2.onload = () => {
    //         // Render the PayPal hosted button
    //         window.paypal.HostedButtons({
    //             hostedButtonId: "GMQHQTKGS9ZTS",
    //         }).render("#paypal-container-hosted");
    //     };
    //     document.body.appendChild(script2);

    //     // Clean up the scripts when the component unmounts
    //     return () => {
    //         document.body.removeChild(script1);
    //         document.body.removeChild(script2);
    //     };
    // }, []);

    useEffect(() => {
        // Dynamically load the PayPal SDK script
        const script = document.createElement('script');
        script.src = "https://www.paypal.com/sdk/js?client-id=BAAKwTUqP_gNTUsh_ATD2tj4zKPzxSZavY2Jt3Lu7MXMv3KD0uxYVEqIKyiDv2_aWpAT2HpYnOIbfX3ZrU&components=hosted-buttons&disable-funding=venmo&currency=USD";
        script.async = true;
        script.crossorigin = "anonymous";
        
        // Append the script to the document
        document.body.appendChild(script);
    
        // Initialize PayPal Hosted Buttons after the script has loaded
        script.onload = () => {
          if (window.paypal) {
            window.paypal.HostedButtons({
              hostedButtonId: "WB9FVJY95S9QE",
            }).render("#paypal-container-WB9FVJY95S9QE");
          }
        };
    
        return () => {
          // Cleanup by removing the script
          document.body.removeChild(script);
        };
      }, []);

    // useEffect(() => {
    //     // Dynamically load the PayPal script
    //     const script = document.createElement('script');
    //     script.src = "https://www.paypal.com/sdk/js?client-id=AbRLlHH8bL6MdlW1bCt9rz96P-nWw6YrSEGyZkoGvsAJnj2OAMUroSTpOR4Uhi0nNMQ4SF_nnljDzDjl&vault=true&intent=subscription";
    //     script.async = true;
    //     script.onload = () => {
    //       // Render the PayPal button once the SDK is loaded
    //       window.paypal.Buttons({
    //         style: {
    //           shape: 'rect',
    //           color: 'gold',
    //           layout: 'vertical',
    //           label: 'subscribe',
    //         },
    //         createSubscription: function(data, actions) {
    //           return actions.subscription.create({
    //             plan_id: 'P-06442856C2045342XM34TRDA', // Replace with your plan ID
    //           });
    //         },
    //         onApprove: function(data, actions) {
    //           alert(`Subscription ID: ${data.subscriptionID}`); // Display subscription ID upon approval
    //         }
    //       }).render('#paypal-button-container'); // Renders PayPal button in the container
    //     };
    //     document.body.appendChild(script);
    
    //     // Cleanup script when component unmounts
    //     return () => {
    //       document.body.removeChild(script);
    //     };
    //   }, []);


    // https://www.paypal.com/ncp/payment/WB9FVJY95S9QE

    
    return (
      <>
        <div>
            <h2>Subscribe with PayPal</h2>
            <div id="paypal-button-container-subscription"></div>

            <div id="paypal-container-WB9FVJY95S9QE"></div>

            {/* <div id="paypal-container-hosted"></div>  */}
                {/* <div id="paypal-button-container"></div>  */}
        </div>
        {/* <Container sx={{ py: 5 }}>
            <Box sx={{ mb: 4, textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom
                    sx={{
                        color: mode === 'light' ? '#000' : '#fff',
                        fontSize: '36px',
                        fontWeight: '600'
                    }}>Pricing</Typography>
                <Typography variant="h3" sx={{ mt: 4, color: mode === 'light' ? '#000' : '#fff' }}>Choose the plan that best fits your needs, cancel anytime</Typography>
            </Box>
            <Tabs value={isMonthly ? 'monthly' : 'annual'} onChange={handleTabChange} centered>
                <Tab label="Monthly Plans" value="monthly" />
                <Tab label="Annual Plans" value="annual" />
            </Tabs>
            <Grid container spacing={4} sx={{ mt: 3 }}>
                {!!plans && plans.filter(plan => isMonthly ? !plan.name.includes('Year') : plan.name.includes('Year'))
                    .map((plan, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Paper elevation={3} sx={{ p: 3, textAlign: 'center', height: '100%' }}>
                                <Typography variant="h6" gutterBottom sx={{ color: '#2196f3', fontSize: '22px', fontWeight: '600' }} >{plan.name}</Typography>
                                <Typography variant="h4" component="div" sx={{ color: 'text.secondary' }}>
                                    ${plan.price}/{isMonthly ? 'Month' : 'Year'}
                                </Typography>
                                <Box sx={{ mt: 2, mb: 2, color: 'text.secondary' }}>
                                    {renderPlanFeatures(plan)}
                                </Box>
                                <Button onClick={() => openCheckout(plan.paddleProductId)} variant="contained" color="primary" fullWidth>
                                    Join now
                                </Button>
                            </Paper>
                        </Grid>
                    ))}
            </Grid>
        </Container> */}
        </>
    );
};

export default PayPalSubscriptionForm;
