import React from 'react';
import { Box, Container, Typography, Grid, Link, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  const theme = useTheme();
  const { palette } = theme;
  const currentYear = new Date().getFullYear();

  return (
    <Box sx={{ backgroundColor: '#1a223f', color: '#fff', py: 6, pt: 4, borderTop: `1px solid ${palette.divider}` }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ mb: 2, color: '#a59f9f', fontSize: '18px'   }}>
              SYRA.ai
            </Typography>
            <Typography variant="body2" sx={{ color: '#fff' }}>
            J M Road, Near Shivajinagar Pune, <br /> Maharashtra 411004
            </Typography>
            <Typography variant="body2" sx={{ color: '#fff', mt: 1 }}>
             Email:  support@syraai.com
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ mb: 2, color: '#fff', fontSize: '18px', color: '#a59f9f', }}>
              Important Links
            </Typography>
            <Link href="/about" color="inherit" underline="none">
              <Typography variant="body2" sx={{ mb: 1, color: '#fff' }}>
                About Us
              </Typography>
            </Link>
            <Link href="/contact" color="inherit" underline="none">
              <Typography variant="body2" sx={{ mb: 1, color: '#fff' }}>
                Contact Us
              </Typography>
            </Link>
            <Link href="/privacy-policy" color="inherit" underline="none">
              <Typography variant="body2" sx={{ mb: 1, color: '#fff' }}>
                Privacy Policy
              </Typography>
            </Link>
            <Link href="/terms-of-service" color="inherit" underline="none">
              <Typography variant="body2" sx={{ mb: 1, color: '#fff' }}>
                Terms of Service
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ mb: 2, color: '#fff', fontSize: '18px', color: '#a59f9f', }}>
              Services
            </Typography>
            <Link href="/text-to-image" color="inherit" underline="none">
              <Typography variant="body2" sx={{ mb: 1, color: '#fff' }}>
                Text-to-Image
              </Typography>
            </Link>
            <Link href="/text-to-audio" color="inherit" underline="none">
              <Typography variant="body2" sx={{ mb: 1, color: '#fff' }}>
                Text-to-Audio
              </Typography>
            </Link>
            <Link href="/story-generator" color="inherit" underline="none">
              <Typography variant="body2" sx={{ mb: 1, color: '#fff' }}>
                Text-to-Speech
              </Typography>
            </Link>
            <Link href="/prompt-generator" color="inherit" underline="none">
              <Typography variant="body2" sx={{ mb: 1, color: '#fff' }}>
                Prompt Generator
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ mb: 2, color: '#fff', fontSize: '18px', color: '#a59f9f' }}>
              Follow Us
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton href="https://twitter.com/syra_ai"  target='_blank' color="inherit" sx={{ color: '#fff' }}>
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://www.linkedin.com/company/syraai" target='_blank'  color="inherit" sx={{ color: '#fff' }}>
                <LinkedInIcon />
              </IconButton>
              <IconButton href="https://facebook.com/ultimiteai" target='_blank'  color="inherit" sx={{ color: '#fff' }}>
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://instagram.com/ultimiteai" target='_blank'  color="inherit" sx={{ color: '#fff' }}>
                <InstagramIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, textAlign: 'left', pt: 1,  }}>
          <Typography variant="body2" sx={{ color: '#fff' }}>
            &copy; {currentYear} SYRA.AI All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
