// material-ui
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import SYRA from 'assets/images/SYRA.png'
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <>
            <Box component="img" src={SYRA} alt="Logo" sx={{ maxWidth: '60%', borderRadius: '4px' }} />
        </>
    );
};

export default Logo;
