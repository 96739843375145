import React, { useContext, useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Modal, IconButton, Drawer, List, ListItem, ListItemText, Divider, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toggleTheme } from 'store/actions';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'context/ThemeContext';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Login from '../authentication/auth-forms/AuthLogin';
import SignUp from '../authentication/auth-forms/AuthRegister';
import { border, borderBottom, width } from '@mui/system';
import useConfig from 'hooks/useConfig';
import AppContext from 'context/AppContext';
import SYRA from 'assets/images/SYRA.png'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: '#1a223f',
  boxShadow: 24,
  p: 4,
};

const Navbar = () => {
  // const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { isDarkMode, setIsDarkMode } = useContext(ThemeContext);
  const context = useContext(AppContext);
  const { state } = context;
  const { openLogin, setOpenLogin } = state;
  const dispatch = useDispatch();
  const theme = useTheme();
  const { mode } = useConfig();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
    setIsDarkMode(!isDarkMode);
  };

  const handleOpenLogin = () => setOpenLogin(true);
  const handleCloseLogin = () => setOpenLogin(false);

  const handleOpenSignup = () => setOpenSignup(true);
  const handleCloseSignup = () => setOpenSignup(false);

  const handleNavigate = (path) => () => {
    navigate(path);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const appBarStyles = {
    top: 0,
    zIndex: 99999,
    boxShadow: 'none',
    color: 'white',
    bgcolor: '#212946',
    transition: 'background-color 0.3s',
  };

  const textColor = 'white';

  return (
    <>
      <AppBar position="sticky" sx={appBarStyles}>
        <Toolbar sx={{}}>
          <Typography sx={{ flexGrow: 1, cursor: 'pointer' }} color={textColor} onClick={handleNavigate('/landing-page')}>
            <Box component="img" src={SYRA} alt="Logo" sx={{ maxWidth: { xs: '20%', md: '8%' }, borderRadius: '4px' }} />
          </Typography>

          {isMobile ? (
            <>
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" sx={{ height: '100%', backgroundColor: '#1a223f', color: '#fff' }} open={drawerOpen} onClose={handleDrawerToggle}>
                <Box sx={{ width: 250, mt: 8, height: '100%', backgroundColor: '#1a223f', color: '#fff' }} role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
                  <List>
                    {/* <ListItem button onClick={handleNavigate('/landing-page')}>
                      <Typography sx={{ color: '#fff' }} >Home</Typography>
                    </ListItem> */}
                    <ListItem button onClick={handleNavigate('/image-modal')}>
                      {/* <ListItemText sx={{color: '#fff'}} primary="Image Modal" /> */}
                      <Typography sx={{ color: '#fff' }} >Text to Image</Typography>
                    </ListItem>
                    <ListItem button onClick={handleNavigate('/image-modal')}>
                      {/* <ListItemText sx={{color: '#fff'}} primary="Image Modal" /> */}
                      <Typography sx={{ color: '#fff' }} >Text to Video</Typography>
                    </ListItem>
                    <ListItem button onClick={handleNavigate('/audio-modal')}>
                      {/* <ListItemText primary="Text to Audio" /> */}
                      <Typography sx={{ color: '#fff' }} >Text to Audio</Typography>
                    </ListItem>
                    <ListItem button onClick={handleOpenLogin}>
                      {/* <ListItemText primary="Login" /> */}
                      <Typography sx={{ color: '#fff' }} >Login</Typography>
                    </ListItem>
                    <ListItem button onClick={handleOpenSignup}>
                      {/* <ListItemText primary="Signup" /> */}
                      <Typography sx={{ color: '#fff' }} >Signup</Typography>
                    </ListItem>
                    <Divider />
                  </List>
                </Box>
              </Drawer>
            </>
          ) : (
            <>
              {/* <Button sx={{ color: textColor }} onClick={handleToggleTheme}>
                <DarkModeIcon /> <Switch onSelect={isDarkMode} />
              </Button> */}
              {/* <Button color="inherit" onClick={handleNavigate('/landing-page')} sx={{ color: textColor }}>
                Home
              </Button> */}
              <Button color="inherit" onClick={handleNavigate('/image-modal')} sx={{ color: textColor }}>
                Text to Image
              </Button>
              <Button color="inherit" onClick={handleNavigate('/video')} sx={{ color: textColor }}>
                Text to Video
              </Button>
              <Button color="inherit" onClick={handleNavigate('/audio-modal')} sx={{ color: textColor }}>
                Text to Audio
              </Button>
              <Button color="inherit" onClick={handleNavigate('/subscription-plan')} sx={{ color: textColor }}>
                Pricing
              </Button>
              <Button color="inherit" onClick={handleOpenLogin} sx={{ color: textColor }}>
                Login
              </Button>
              <Button color="inherit" onClick={handleOpenSignup} sx={{ color: textColor }}>
                Signup
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Modal open={openLogin} onClose={handleCloseLogin}>
        <Box sx={{ ...style, width: '340px', mt: 4, padding: '25px' }}>
          <Login />
        </Box>
      </Modal>

      <Modal open={openSignup} onClose={handleCloseSignup}>
        <Box sx={{ ...style, width: '340px', mt: 4, padding: '25px' }}>
          <SignUp />
        </Box>
      </Modal>
    </>
  );
};

export default Navbar;
