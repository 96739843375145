import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, Container, Box, Tabs, Tab, Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import Navbar from './Navbar';

const PricingPage = () => {
    const theme = useTheme();
    const { mode } = theme.palette;
    const [plans, setPlans] = useState([]);
    const [isMonthly, setIsMonthly] = useState(true);
    const serving_url = process.env.REACT_APP_SERVICE_URL;

    useEffect(() => {
        axios.get(`${serving_url}/api/auth/subscription_plans`)
            .then(response => setPlans(response?.data?.plans))
            .catch(error => console.error('Error fetching subscription plans:', error));
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
        script.async = true;
        script.onload = () => {
            if (window.Paddle) {
                window.Paddle.Environment.set('sandbox'); // Set sandbox mode
                window.Paddle.Initialize({
                    token: "test_4bf2a50e372cec5197048fac0c9" // replace with a client-side token
                });
                // window.Paddle.Setup({ seller: 21731 }); // Replace with your actual Vendor ID
            }
            // if (window.Paddle) {
            //     // window.Paddle.Environment.set('vendors'); // Set live mode
            //     window.Paddle.Setup({ seller: 199907 }); // Replace with your actual Vendor ID
            //     window.Paddle.Initialize({
            //         token: "live_6359c28c3d9b5430a1e33f9ca2d" // replace with a client-side token
            //     });

            // }
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleTabChange = (event, newValue) => {
        setIsMonthly(newValue === 'monthly');
    };

    const renderPlanFeatures = (plan) => (
        <>
            {plan?.features?.included?.map((feature, i) => (
                <Box key={i} display="flex" alignItems="center">
                    <CheckIcon color="primary" />
                    <Typography variant="body2" sx={{ ml: 1, color: '#d1d5db' }}>{feature}</Typography>
                </Box>
            ))}
            {plan?.features?.excluded?.map((feature, i) => (
                <Box key={i} display="flex" alignItems="center">
                    <CloseIcon color="disabled" sx={{color: 'rgb(89 95 116)'}} />
                    <Typography variant="body2" sx={{ ml: 1, color: '#d1d5db' }}>{feature}</Typography>
                </Box>
            ))}
        </>
    );



    const openCheckout = (id) => {
        if (window.Paddle) {
            let itemsList = [
                {
                    priceId: id,
                },
            ];


            window.Paddle.Checkout.open({
                items: itemsList,
                customer: 'raojipatil11@gmail.com',
                successCallback: () => {
                    // Handle success (e.g., show a success message)
                    alert('Payment was successful!');
                },
                closeCallback: () => {
                    // Handle close event (e.g., reset UI state)
                }
            });
        }
    };

    return (
        <Box sx={{ bgcolor: '#1a223f', height: '100vh' }}>
               <Navbar />
            <Box sx={{ mb: 4, textAlign: 'center', pt: '20px' }}>
                <Typography variant="h4" gutterBottom
                    sx={{
                        color: '#fff',
                        fontSize: '36px',
                        fontWeight: '600'
                    }}>Pricing</Typography>
                <Typography variant="body1" sx={{color: '#fff'}}>Choose the plan that best fits your needs, cancel anytime.</Typography>
            </Box>
            <Tabs value={isMonthly ? 'monthly' : 'annual'} onChange={handleTabChange} centered>
                <Tab sx={{ color: '#fff' }} label="Monthly Plans" value="monthly" />
                <Tab sx={{ color: '#fff' }} label="Annual Plans" value="annual" />
            </Tabs>
            <Grid container spacing={4} sx={{ mt: 3, padding: '2% 10%',  bgcolor: '#1a223f', }}>
                {!!plans && plans.filter(plan => isMonthly ? !plan.name.includes('Year') : plan.name.includes('Year'))
                    .map((plan, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Paper elevation={3} sx={{ p: 3, textAlign: 'center', bgcolor: '#212946', height: '100%' }}>
                                <Typography variant="h6" gutterBottom sx={{ color: '#2196f3', fontSize: '22px', fontWeight: '600' }} >{plan.name}</Typography>
                                <Typography variant="h4" component="div" sx={{ color: '#fff' }}>
                                    ${plan.price}/{isMonthly ? 'Month' : 'Year'}
                                </Typography>
                                <Box sx={{ mt: 2, mb: 2, color: 'text.secondary' }}>
                                    {renderPlanFeatures(plan)}
                                </Box>
                                <Button onClick={() => openCheckout(plan.paddleProductId)} variant="contained" color="primary" fullWidth>
                                    Join now
                                </Button>
                            </Paper>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default PricingPage;
