import { useContext, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    LinearProgress,
    Typography,
} from '@mui/material';

import AppContext from 'context/AppContext';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

// ==============================|| CREDITS ||============================== //

const VideoCredits = () => {
    const theme = useTheme();

    const context = useContext(AppContext);
    const { state } = context;
    const { credits, userData, apiLoading, setRemainingCredits, videoCredits } = state;

    const useCredits = videoCredits || 0;
    const totalCredits = userData?.videoCredits || 0;
    const remainingCredits = totalCredits - useCredits;
    setRemainingCredits(remainingCredits)
    const progress = (remainingCredits / totalCredits) * 100;

    return (
        <>
            {!apiLoading && (
                <Box
                    sx={{
                        ml: 2,
                        mr: 3,
                        [theme.breakpoints.down('md')]: {
                            mr: 2,
                        },
                    }}
                >
                    <Typography variant="body1" gutterBottom>
                        Video credits: {remainingCredits}/{totalCredits}
                    </Typography>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        sx={{
                            height: 10,
                            borderRadius: 5,
                            backgroundColor: theme.palette.grey[300],
                            '& .MuiLinearProgress-bar': {
                                borderRadius: 5,
                                backgroundColor: theme.palette.primary.main,
                            },
                        }}
                    />
                </Box>
            )}
        </>
    );
};

export default VideoCredits;
