import { useContext, useEffect, useState } from 'react';
import { Grid, TextField, Button, MenuItem, Typography, CircularProgress } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box } from '@mui/system';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import DownloadImage from './DownloadImage';
import AppContext from 'context/AppContext';



const CreateVideo = () => {
    const theme = useTheme();
    const [prompt, setPrompt] = useState('');
    const [negativePrompt, setNegativePrompt] = useState('');
    const [video, setVideo] = useState([]);

    const [generated, setGenerated] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const serving_url = process.env.REACT_APP_SERVICE_URL

    const context = useContext(AppContext);
    const { state } = context;
    const { selectedVidProject, credits, setCredits, remainingCredits, userData, videoCredits, setVideoCredits } = state;
    const [readyToShow, setReadyToShow] = useState(false);
    const [progress, setProgress] = useState(0);

    const fetchQueuedImages = (task_id) => {
        setTimeout(() => handleGenerateTResult(task_id), 5000);
    };

    const handleGenerate = async () => {
        if (remainingCredits <= 0) {
            return false
        }

        setLoading(true);
        setError('');
        setVideo([])
        setGenerated([])
        setProgress(0);


        try {
            const response = await axios.post(`${serving_url}/api/text-to-video`, {
                prompt,
                uid: userData?.email,
                negative_prompt: negativePrompt,
                height: 512,
                samples: 4,
                projectId: selectedVidProject._id
            });

            setTimeout(() => {
                handleGenerateTResult(response.data.response.task_id);
            }, 500);

        } catch (error) {
            setError('Error generating video');
            console.error('Error generating video:', error);
        }
    };

    const handleGenerateTResult = async (task_id) => {
        setVideo([]);
        setReadyToShow(false);
        setLoading(true);
        setError('');
        try {
            const response = await axios.post(`${serving_url}/api/video-result`, {
                task_id,
                prompt,
                uid: userData?.email,
                projectId: selectedVidProject._id
            });
            if (["pending", "staged", "starting", "processing"].includes(response.data.response.status)) {
                if (response.data.response.percentage) {
                    setProgress(response.data.response.percentage);
                }
                fetchQueuedImages(response.data.response.task_id);
            } else if (response.data.response.video_url) {
                setVideo(response.data.response.video_url);
                setLoading(false);
                setReadyToShow(true);
                const creditsLeft = Math.max(0, videoCredits + 1);
                setVideoCredits(creditsLeft);

            } else {
                setLoading(false);
                setError('Error generating video');
                console.error('Error generating video:', error);
            }
        } catch (error) {
            setError('Error generating video');
            console.error('Error generating video:', error);
            setLoading(false);
        }
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={4}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <TextField
                        fullWidth
                        label="Enter Prompt"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        multiline
                        rows={12}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#F8FAFC',
                                '&.Mui-focused': {
                                    backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#F8FAFC',
                                },
                                '& fieldset': {
                                    borderColor: theme.palette.divider,
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.primary.main,
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: theme.palette.text.primary,
                            },
                            '& .MuiInputBase-input': {
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F8FAFC',
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddPhotoAlternateIcon />}
                        onClick={handleGenerate}
                        fullWidth
                        sx={{ mt: 2 }}
                        disabled={loading || remainingCredits <= 0}
                    >
                        {loading ? 'Generating...' : 'Generate'}
                    </Button>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="400px"
                    border={`1px dashed ${theme.palette.divider}`}
                    sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}
                >
                    {loading && progress === 0 ? (
                        <CircularProgress />
                    ) : loading && progress >= 0 ? (
                        <Box position="relative" >
                            <CircularProgress variant="determinate" value={progress} />
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
                            </Box>
                        </Box>
                    ) : video.length > 0 ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <video
                                    src={video}
                                    controls
                                    style={{ width: '100%', height: 'auto' }}
                                >
                                    <track
                                        kind="captions"
                                        srcLang="en"
                                        src="path/to/captions.vtt" // Replace with your captions file path
                                        label="English"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </Grid>
                        </Grid>
                    ) : (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Typography variant="h6" color="textSecondary" gutterBottom>
                                No Video generated yet
                            </Typography>
                        </Box>
                    )}
                </Box>


            </Grid>
        </Grid>
    );
};

export default CreateVideo;
